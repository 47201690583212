import React from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Table } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TTableHeader } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CategoriesDropdown } from '../filter-categories/CategoriesDropdown';
import Loader from '../loader/Loader';
import { TableContentProps, TTableLoadMoreProps } from '../table/Table';

const HEADERS = [
	{
		headerLabel: 'Name',
		width: getResponsiveStyle(240),
	},
	{
		headerLabel: 'Status',
		width: getResponsiveStyle(192),
	},
	{
		headerLabel: 'Location',
		width: getResponsiveStyle(192),
	},
	{
		headerLabel: 'Email',
		width: getResponsiveStyle(300),
	},
	{
		headerLabel: 'Role',
		width: getResponsiveStyle(100),
	},
];

const SORT_BY_FILTER = [
	{
		label: 'Revenue',
		value: 'revenue',
	},
	{
		label: 'Orders',
		value: 'orders',
	},
	{
		label: 'Value',
		value: 'value',
	},
	{
		label: 'Refund',
		value: 'refund',
	},
];
const DATE_FILTER = [
	{
		label: 'Last week',
		value: 'last_year',
	},
	{
		label: 'Last month',
		value: 'last_month',
	},
	{
		label: 'Last 3 months',
		value: 'last_3_months',
	},
	{
		label: 'Last year',
		value: 'last_year',
	},
];

type Props = {
	type?:
		| 'brand'
		| 'profile'
		| 'order'
		| 'voucher'
		| 'Admin'
		| 'Events'
		| 'singleOrder'
		| 'tag'
		| 'product';
	showHeader?: boolean;
	headerTabs?: string[];
	onPressTab?: (tab: string) => void;
	selectedTab?: string;
	data?: any;
	dataDetail?: any;
	headers?: TTableHeader[];
	loading?: boolean;
	showScrollBar?: boolean;
	tableContentProps?: TableContentProps;
	loadMoreObject?: TTableLoadMoreProps;
};

const TableComponent = ({
	type,
	showHeader = true,
	headerTabs = [],
	onPressTab,
	selectedTab,
	data,
	dataDetail,
	headers,
	loading,
	showScrollBar = true,
	tableContentProps,
	loadMoreObject,
}: Props) => {
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View style={styles.container}>
			{showHeader && (
				<View style={styles.headerContainer}>
					<ScrollView
						horizontal={true}
						showsHorizontalScrollIndicator={false}
						style={styles.tabContainer}
						contentContainerStyle={{ height: 30 }}
					>
						{headerTabs.map(tab => (
							<Pressable
								key={tab}
								onPress={() => onPressTab && onPressTab(tab)}
							>
								<Text
									style={styleTab(
										selectedTab ?? '',
										tab,
										width
									)}
								>
									{tab}
								</Text>
							</Pressable>
						))}
					</ScrollView>
					{/* {!isLessThanDesktopBase && (
						<View style={styles.filterContainer}>
							<CategoriesDropdown
								categories={SORT_BY_FILTER}
								label=""
								placeholder="Sort by"
							/>
							<CategoriesDropdown
								categories={DATE_FILTER}
								label=""
								placeholder="Date"
							/>
						</View>
					)} */}
				</View>
			)}

			<View style={styles.tableContainer}>
				{loading === true && loading !== undefined ? (
					<Loader />
				) : (
					<Table
						content={data ?? []}
						dataDetail={dataDetail}
						headers={headers ?? HEADERS}
						type={type}
						loadMoreObject={loadMoreObject}
						tableContentProps={
							tableContentProps
								? tableContentProps
								: type === 'Admin'
								? {
										messageButton: false,
										profileButton: false,
										followButton: false,
										adminButton: true,
								  }
								: type === 'Events'
								? {
										messageButton: false,
										profileButton: false,
										followButton: false,
										viewButton: false,
								  }
								: {
										messageButton: false,
										profileButton: false,
										followButton: false,
										viewButton: true,
								  }
						}
					/>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
	},
	tabContainer: {
		flexDirection: 'row',
		// alignItems: 'center',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: getResponsiveStyle(24),
	},

	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
		minHeight: getResponsiveStyle(40, 'dimensions'),
	},
	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],
		fontSize: getResponsiveStyle(14, 'font'),

		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		// paddingBottom: 9,
		fontSize: getResponsiveStyle(14, 'font'),
		// paddingHorizontal: 3,

		fontWeight: 'bold',
		// borderBottomColor: palettes.primary[0],
		// borderBottomWidth: 1,
	},
	tableContainer: {
		width: '100%',
	},
});

export default TableComponent;
const styleTab = (selectedTab: string, tab: string, width: number) => {
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			lineHeight: getResponsiveStyle(18, 'font'),
		},
		selectedTab === tab ? styles.tabSelected : styles.tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};
