import { useMutation } from '@apollo/client';
import { TCreateVoucher, TCreateVoucherResponse } from '../../types/voucherTypes';
import { CREATE_VOUCHER } from '../../graphql/voucher/mutations';

type UseCreateVoucherProps = {
  onCompleted?: (data: TCreateVoucherResponse) => void;
  onError?: (error: any) => void;
};

const useCreateVoucher = ({ onCompleted, onError }: UseCreateVoucherProps = {}) => {
  const [createVoucherMutation, { data, loading, error }] = useMutation<
    TCreateVoucherResponse,
    { args: TCreateVoucher }
  >(CREATE_VOUCHER, {
    onCompleted,
    onError,
  });

  const executeCreateVoucherMutation = (args: TCreateVoucher) => {
    return createVoucherMutation({
      variables: {
        args,
      },
    });
  };

  return {
    createVoucher: executeCreateVoucherMutation,
    data,
    loading,
    error,
  };
};

export default useCreateVoucher; 