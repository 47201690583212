import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Checkbox } from '../..';
import { palettes } from '../../../config';
import { TTableHeader } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	headers: TTableHeader[];
	type?:
		| 'brand'
		| 'profile'
		| 'order'
		| 'voucher'
		| 'Admin'
		| 'Events'
		| 'singleOrder'
		| 'tag'
		| 'product';
	hasCheckbox?: boolean;
};

export const TableHeader = ({
	headers,
	type = 'order',
	hasCheckbox = false,
}: Props) => {
	return (
		<View style={[styles.container, styleContainer()]}>
			{hasCheckbox && (
				<View
					style={{
						height: 15,
						width: 15,
						marginRight: 50,
					}}
				></View>
			)}
			{headers.map(({ headerLabel, width = 150 }, index) => (
				<View key={index} style={[styleHeader(width)]}>
					<Text style={[styles.header]}>{headerLabel}</Text>
				</View>
			))}
			{type === 'order' && <Text style={styles.action}></Text>}
			{/* {type === 'profile' && <Text style={styles.action}>Watch</Text>} */}
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		fontWeight: '400',
		color: palettes.grey[0],
		textTransform: 'uppercase',
	},
	container: {
		flexDirection: 'row',
	},
	action: {
		flex: 1,
		fontWeight: '400',
		color: palettes.grey[0],
		textTransform: 'uppercase',
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	},
});

const styleHeader = (width: string | number) => {
	return {
		width,
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleContainer = () => {
	return {
		marginBottom: getResponsiveStyle(10),
		paddingHorizontal: getResponsiveStyle(15),
	};
};
