import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAdminRole } from '../types/adminTypes';
import { TError, TSidebarRoute } from '../types/types';
import { getWidth } from '../utils/getWidth';
import { setLoggedInUserDetails } from './authSlice';

export const SIDEBAR_ROUTES: TSidebarRoute[] = [
	{ id: 1, screen: 'Dashboard' },
	{ id: 2, screen: 'Events' },
	{ id: 3, screen: 'Brands' },
	{ id: 4, screen: 'Host' },
	{ id: 5, screen: 'Sellers' },
	{ id: 6, screen: 'Guests' },
	{ id: 7, screen: 'Products' },
	{ id: 8, screen: 'Accounts' },
	{ id: 9, screen: 'Delivery' },
	{ id: 10, screen: 'Voucher' },
	{ id: 11, screen: 'Tags' },
];

type TInitialState = {
	width: number;
	alerts: TError[];
	globalLoading: boolean;
	sidebarArray: TSidebarRoute[];
};

type TIdsToFilter = {
	[T in TAdminRole]: number[];
};

const IdsToKeepForUsers: TIdsToFilter = {
	SUPER_ADMIN: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // All Screens
	BRAND_ADMIN: [3, 7, 10], // Brand admin
	ADMIN: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // All Screens
	BRAND: [1, 3, 7, 6, 10], //Dashboard Brands Products
	EVENT: [1, 2, 6], //Dashboard Events
	HOST: [1, 4, 6], //Dashboard Host
	SELLER: [1, 5, 6], //Dashboard Seller
};

const initialState: TInitialState = {
	width: getWidth(),
	alerts: [],
	globalLoading: false,
	sidebarArray: SIDEBAR_ROUTES,
};

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setScreenWidth(state, action: PayloadAction<number>) {
			state.width = action.payload;
		},
		setAlerts(state, action: PayloadAction<TError[]>) {
			state.alerts = action.payload;
		},
		setGlobalLoading(state, action: PayloadAction<boolean>) {
			state.globalLoading = action.payload;
		},
		resetState() { },
	},
	extraReducers: builder => {
		builder.addCase(setLoggedInUserDetails, (state, action) => {
			const roleOfAdmin = action.payload.role;

			if (!!roleOfAdmin) {
				if (roleOfAdmin !== 'SUPER_ADMIN') {
					state.sidebarArray = SIDEBAR_ROUTES.filter(route =>
						/* keep only those ids which are specified in IdsToKeepForUsers */
						IdsToKeepForUsers[roleOfAdmin].includes(route.id)
					);
				} else {
					state.sidebarArray = SIDEBAR_ROUTES;
				}
			}
		});
	},
});

export const { setScreenWidth, setAlerts, setGlobalLoading, resetState } =
	uiSlice.actions;

export default uiSlice.reducer;
