import { gql } from '@apollo/client';

export const GET_ALL_VOUCHERS = gql`
  query getAllVouchers($args: GetUserVoucherInput) {
    getAllUserVouchers(args: $args) {
        statusCode
        success
        ...on AllResponse {
        statusCode
        data {
            total
            data {
            ...on UserVoucher {
                    id
                    code
                    description
                    discountPercentage
                    maxUses
                    expiryDate
                    currentUses
                  }
              }
            }
        }
    }
  }
`;

export const GET_VOUCHER_BY_ID = gql`
  query getUserVoucher($args: GetUserVoucherInput) {
      getUserVoucher(args: $args){
      statusCode
      message
      success
      ...on Response {
        data {
          ...on UserVoucher {
            id
            code
            description
            discountPercentage
            maxUses
            expiryDate
            applicableBrandId {
              _id
            }
          }
        }
      }
    }
  }
`; 