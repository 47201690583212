import { Picker, PickerProps } from '@react-native-picker/picker';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useNavigation } from '@react-navigation/native';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { ValueType } from 'react-native-dropdown-picker';
import Icon from 'react-native-vector-icons/Feather';
import styled from 'rn-css';
import { object } from 'yup/lib/locale';
import { Button } from '../..';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { TODO, TTableHeader, TUseNavigation } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProductDetail from '../../products/product-cards/ProductDetail';
import ProductPriceInfo from '../../products/product-cards/ProductDescription';
import Checkbox from '../checkbox/Checkbox';
import ModalComponent, { TModalProps } from '../modal/ModalComponent';
import ProfileIcon from '../ProfileIcon';
import { TableContentProps } from './Table';
import { useDispatch } from 'react-redux';
import { setProductTableDetail } from '../../../redux/productsSlice';
import { manipulateString } from '../../../utils/manipulateString';
import ChatChild from '../../chat/Chat';
import { useAppSelector } from '../../../redux/store';
import useGetConversation from '../../../api/chat/useGetConversation';
import useCreateMessage from '../../../api/chat/useCreateMessage';
import useGetConversationByParticipants from '../../../api/chat/useGetConversationByParticipants';
import { setCurrentChat } from '../../../redux/chatSlice';
import { socket } from '../../../../App';
import TagDeletion from '../../tags/TagDeletion';
import { updateOneChat } from '../../../utils/helper';
import { DISABLE_PROFILE, EDIT_USER } from '../../../graphql/profile/mutation';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useMutation } from '@apollo/client';
import { DELETE_PRODUCT } from '../../../graphql/product/mutation';

const ContentContainer = styled.View``;

const RoleDropdownOptions = [
	{ value: 'BRAND', label: 'Brand' },
	{ value: 'EVENT', label: 'Event' },
	{ value: 'SELLER', label: 'Seller' },
	{ value: 'HOST', label: 'Host' },
	{ value: 'ADMIN', label: 'Admin' },
];

const ProductStatus = [
	{ value: 'DRAFT', label: 'Draft' },
	{ value: 'ACTIVE', label: 'Active' },
];
export const placeholderImage =
	'https://ocpus-dev.s3.ap-southeast-1.amazonaws.com/dac834ba-12e8-45c3-b4de-ae832cabb440.png';

export type Props<T> = {
	user: T | any;
	dataDetail: any;
	headers: TTableHeader[];
	id: number;
	type?:
	| 'brand'
	| 'profile'
	| 'order'
	| 'Admin'
	| 'Events'
	| 'singleOrder'
	| 'tag'
	| 'voucher'
	| 'product';
	messageButton?: boolean;
	profileButton?: boolean;
	followButton?: boolean;
	adminButton?: boolean;
	viewButton?: boolean;
	hasCheckbox?: boolean;
	onChangeCheckbox?: (id: number) => void;
	onChangePickerVal?: (value: string, id: number) => void;
	onChangeDropdown?: (value: string, index: number, userId: string) => void;
} & TableContentProps;

export const TableContent = <T extends unknown>({
	user,
	dataDetail,
	headers,
	id,
	type,
	messageButton = false,
	profileButton = true,
	followButton = true,
	adminButton = false,
	viewButton = false,
	hasCheckbox = false,
	onChangeCheckbox,
	onChangePickerVal,
	onChangeDropdown = () => { },
}: Props<T>) => {
	const [userData, setUserData] = useState<any[]>();
	const [isChecked, setIsChecked] = useState<undefined | boolean>();
	const [userImage, setUserImage] = useState<string>();
	const [hovered, setHovered] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const [modalDetails, setModalDetails] = useState<TModalProps>();
	const [messageOpen, setMessageOpen] = useState(false);
	const [modalChild, setModalChild] = useState<JSX.Element>(<></>);
	const dispatch = useDispatch();
	const [userId, setUserId] = useState<string>();
	const [date, setDate] = useState<Date>();
	const [qty, setQty] = useState<number>(0);
	const [conversationId, setConversationId] = useState('');
	const [messages, setMessages] = useState<TODO[]>([]);
	const [message, setMessage] = useState('');
	const [flexChild, setFlexChild] = useState(false);
	const [isRenderChat, setIsRenderChat] = useState(false);
	const [isRenderChatOnMessage, setIsRenderChatOnMessage] = useState(false);
	let userPicture: undefined | string;
	let userRole: undefined | string | unknown;

	console.log({ dataDetail });


	const chatRef = useRef();
	const { setAlert } = useSetAlert();

	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const [disableProfile, { loading: loadingEditUser }] = useMutation(
		DISABLE_PROFILE,
		{
			onCompleted: data => {
				if (data?.disableProfile?.success) {
					setAlert(data?.disableProfile?.message, 'normal');
					// getUser();
				} else {
					setAlert('Unable to update user information', 'danger');
				}
			},
			onError: () => {
				setAlert('Unable to update user information', 'danger');
			},
		}
	);

	const [deleteProduct, { loading: loadingDeleteProduct }] = useMutation(
		DELETE_PRODUCT,
		{
			onCompleted: data => {
				if (data?.deleteProduct?.success) {
					setAlert(data?.deleteProduct?.message, 'normal');
					// getUser();
				} else {
					setAlert('Unable to update user information', 'danger');
				}
			},
			onError: () => {
				setAlert('Unable to update user information', 'danger');
			},
		}
	);

	const { executeConversationMutation, data: conversationData } =
		useGetConversationByParticipants();

	useEffect(() => {
		executeConversationMutation({
			participants: [loggedInUserDetails?._id, user._id],
		});

		setFlexChild(true);

		socket.on('sendMessage', message => {
			setMessages(prev => updateOneChat(prev, message.message));
		});
	}, []);

	useEffect(() => {
		const conversationId =
			conversationData?.getConversationByParticipants?.data?.id;
		if (conversationId) {
			setConversationId(conversationId);
		}
	}, [conversationData]);

	const { executeConversationQuery, data: conversationMessageData } =
		useGetConversation();

	useEffect(() => {
		executeConversationQuery({
			conversationId,
		});
	}, [conversationId]);

	useEffect(() => {
		const messageData =
			conversationMessageData?.getOneConversationByUserId?.data?.messages;
		if (messageData?.length) {
			setMessages(messageData);
		}
	}, [conversationMessageData]);

	const { executeCreateMessageMutation } = useCreateMessage();

	const handleMessageSend = ({
		message,
		messageType = 'TEXT',
	}: {
		message: string;
		messageType: string;
	}) => {
		socket.emit('sendMessage', {
			message: {
				message: message,
				recipients: user._id,
				sender: loggedInUserDetails?._id,
				messageType: messageType,
				recipientsReadReceipt: false,
				createdAt: new Date().getTime(),
			},
			contacts: {
				contactPerson: loggedInUserDetails?._id,
				conversationId: conversationId,
			},
			recipients: user._id,
		});

		setMessages(prev => [
			...prev,
			{
				message: message,
				recipients: user._id,
				messageType: messageType,
				sender: loggedInUserDetails?._id,
			},
		]);
		setIsRenderChatOnMessage(true);
		executeCreateMessageMutation({
			sender: loggedInUserDetails?._id as string,
			recipients: user._id,
			message,
			messageType: messageType,
			conversationId: conversationId,
		});
	};

	const Product_Draft_Status = [
		{ value: 'DRAFT', label: 'Draft' },
		{ value: 'ACTIVE', label: 'Active' },
	];

	const Product_Active_Status = [
		{ value: 'ACTIVE', label: 'Active' },
		{ value: 'DRAFT', label: 'Draft' },
	];

	useEffect(() => {
		if (type === 'product') {
			setUserImage(user[0]);
		}
		if (type === 'order') {
			user?.items?.map((product: any) => setQty(qty + product.quantity));

			setDate(new Date(Number(user.purchasedOn)));
		} else if (type === 'singleOrder') {
			const x = Object.values(user);
			setUserData(x);
		} else if (type === 'brand') {
			const x = Object.values(user);
			userPicture = x.shift() as string;
			setUserImage(userPicture);
			let id: string = new String(x.pop()).toString();
			setUserId(id);
			setUserData(x);
		} else if (!!user) {
			const x = Object.values(user);

			if (hasCheckbox) {
				setIsChecked(user[user.length - 1]);
				const _userData = Object.values(user);

				// filter image and isChecked value
				const filteredUserData = _userData.filter(
					(_, i) => i !== 0 && i !== user.length - 1
				);

				setUserData(filteredUserData);
			} else if (type === 'profile') {
				userPicture = x.shift() as string;
				setUserImage(userPicture);
				let id: string = new String(x.pop()).toString();
				setUserId(id);
				setUserData(x);
			} else if (type === 'Admin') {
				// userPicture = x.shift() as string;
				userRole = x.pop();
				setDropdownValue(userRole as string);
				setUserData(x);
			} else if (type === 'voucher') {
				setUserData(x);
			} else {
				userPicture = x.shift() as string;
				setUserImage(userPicture);
				setUserData(x);
			}
		}
	}, [user]);

	const handleMessageClick = () => {
		// setMessageOpen(true);
		dispatch(setCurrentChat(user));
		setModalDetails({
			header: 'Message',
			children: <></>,
			onClose: () => {
				setIsModal(false);
				setIsRenderChat(false);
				setIsRenderChatOnMessage(false);
			},
			openState: isModal,
			type: 'sidebar',
			slideInRight: true,
			isScrollable: false,
		});
		setIsModal(!isModal);
		setIsRenderChat(true);
	};

	useEffect(() => {
		if (isRenderChat || messages.length) {
			setModalChild(
				<ChatChild
					messages={messages}
					handleMessageSend={handleMessageSend}
					setMessage={setMessage}
					scrollViewRef={chatRef}
				/>
			);
		}
	}, [isRenderChat, messages]);

	const [dropdownValue, setDropdownValue] = useState<
		PickerProps['selectedValue']
	>(RoleDropdownOptions[0].value);
	const navigation = useNavigation<TUseNavigation>();
	return (
		<Pressable style={{ zIndex: 5000 }}>
			<ModalComponent
				// header="Products View"
				onClose={() => setIsModal(false)}
				// type="sidebar"
				openState={isModal}
				type={modalDetails?.type}
				header={modalDetails?.header ?? '...'}
				isScrollable={modalDetails?.isScrollable}
				slideInRight={modalDetails?.slideInRight}
				flexChild={flexChild}
			>
				{modalChild}
			</ModalComponent>
			{type === 'profile' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					style={styleContentContainer(hovered)}
				>
					{hasCheckbox && (
						<View style={{ marginRight: getResponsiveStyle(24) }}>
							<Checkbox
								onChange={() => setIsChecked(!isChecked)}
								isChecked={isChecked}
							/>
						</View>
					)}
					{userData &&
						userData.length &&
						userData.map((data, index) => (
							<Text
								key={index}
								numberOfLines={1}
								style={[
									styles.textStyle,

									styleUserData(headers, index, hovered),
								]}
							>
								{index === 0 && (
									<ProfileIcon
										source={
											!!userImage
												? { uri: userImage }
												: {
													uri: placeholderImage,
												}
										}
										style={styleProfileIcon()}
									/>
								)}
								{data}
							</Text>
						))}

					<View style={styles.actionContainer}>
						{messageButton && (
							<Button
								onPress={handleMessageClick}
								title={'Message'}
							/>
						)}
						{profileButton && (
							<>
								<Button
									title={
										user?.status === 'Active'
											? 'Remove'
											: 'Disabled'
									}
									loading={loadingEditUser}
									variant={
										user?.status === 'Active'
											? 'outline'
											: 'disabled'
									}
									onPress={() => {
										disableProfile({
											variables: {
												email: userData?.[6] ?? '',
											},
										});
									}}
									outlineColor={palettes.red[0]}
									outerContainerProps={{
										style: {
											width: getResponsiveStyle(90),
										},
									}}
								/>
								<Button
									title={'View Profile'}
									variant="grey"
									onPress={() => {
										navigation.navigate('UserProfile', {
											userId: userId ?? '',
										});
									}}
								/>
							</>
						)}

						{/* <Icon
							name="more-horizontal"
							style={{
								marginLeft: getResponsiveStyle(25),
							}}
							size={20}
							color={palettes.grey[10]}
						/> */}
					</View>
				</ContentContainer>
			)}

			{type === 'product' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					style={styleContentContainer(hovered)}
				>
					{/* {hasCheckbox && (
						<View style={{ marginRight: getResponsiveStyle(24) }}>
							<Checkbox
								onChange={() => {
									onChangeCheckbox && onChangeCheckbox(id);
									setIsChecked(!isChecked);
								}}
								isChecked={isChecked}
							/>
						</View>
					)} */}
					{userData &&
						userData.length &&
						userData.map((data, index) => (
							<Pressable
								key={index}
								onPress={() => {
									if (
										dataDetail?.[0]?.__typename ===
										'Product'
									) {
										setModalDetails({
											header: 'Product View',
											children: <></>,
											onClose: () => setIsModal(false),
											openState: isModal,
											type: 'sidebar',
											slideInRight: true,
											isScrollable: true,
										});
										setModalChild(
											<ProductDetail {...user} />
										);
										setIsModal(!isModal);

										const product = dataDetail.find(
											(item: any, index: number) =>
												index === id
										);
										dispatch(
											setProductTableDetail(product)
										);
									}
								}}
							>
								<Text
									numberOfLines={1}
									style={[
										styles.textStyle,
										styleUserData(headers, index, hovered),
									]}
								>
									{index ===
										userData.indexOf(
											userData[userData.length - 1]
										) ? (
										<Button
											title={'Edit'}
											variant="primary"
											onPress={() => {
												const findProduct = dataDetail?.find((detail: any) => detail?._id === data)
												if (findProduct?.variants?.[0]?.option?.variatorValues) {
													navigation.navigate(
														'ProductsSingleUpload',
														{
															productId: data,
														}
													);
												} else {
													navigation.navigate(
														'ProductsSingleUploadOld',
														{
															productId: data,
														}
													);
												}
											}}
										/>
									) : (
										<>
											{index === 0 && (
												<ProfileIcon
													source={
														!!userImage
															? { uri: userImage }
															: {
																uri: placeholderImage,
															}
													}
													style={styleProfileIcon()}
												/>
											)}
											{data || 'N/A'}
											{index === 5 &&
												<>
													<Button title="Delete"
														outlineColor={palettes.red[0]}
														onPress={() => {
															console.log({ data: userData[userData.length - 1] });
															deleteProduct({
																variables: {
																	_id: userData[userData.length - 1]
																}
															})
														}}
														loading={loadingDeleteProduct}
														outerContainerProps={{
															style: {
																marginLeft: 10
															}
														}}
													/>
												</>
											}
										</>
									)}
								</Text>
							</Pressable>
						))}

					{/* <View style={styles.actionContainer}>
						{messageButton && <Button title={'Message'} />}
						{profileButton && (
							<Button
								title={'View Profile'}
								variant="grey"
								onPress={
									() => <></>
									// navigation.navigate('UserProfile', {
									// 	userId: user?._id,
									// })
								}
							/>
						)}

						<Icon
							name="more-horizontal"
							style={{
								marginLeft: getResponsiveStyle(25),
							}}
							size={20}
							color={palettes.grey[10]}
						/>
					</View> */}
					<Picker
						selectedValue={dropdownValue}
						onValueChange={itemValue =>
							onChangePickerVal &&
							onChangePickerVal(itemValue, id)
						}
						style={[styles.dropdownContainer]}
						dropdownIconColor={palettes.grey[1]}
						itemStyle={styles.dropdownItem}
					>
						{user[5] === 'DRAFT'
							? Product_Draft_Status.map(option => (
								<Picker.Item {...option} />
							))
							: Product_Active_Status.map(option => (
								<Picker.Item {...option} />
							))}
					</Picker>
				</ContentContainer>
			)}
			{type === 'Admin' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					style={styleContentContainer(hovered)}
				>
					{console.log({ userData })}
					{userData &&
						userData.length &&
						userData
							.filter((d, i) => i !== 0)
							.map((data, index) => (
								<Text
									key={index}
									numberOfLines={1}
									style={[
										styles.textStyle,
										styleUserData(headers, index, hovered),
									]}
								>
									{/* {index === 0 && (
									<ProfileIcon
										source={
											!!userImage
												? { uri: userImage }
												: {
														uri: 'http://placekitten.com/500',
												  }
										}
										style={styleProfileIcon()}
									/>
								)} */}
									{data}
								</Text>
							))}

					<Picker
						selectedValue={dropdownValue}
						onValueChange={(value, index) => {
							// setDropdownValue(value);
							userData &&
								onChangeDropdown(
									value,
									index,
									userData[0] as string
								);
						}}
						style={[styles.dropdownContainer]}
						dropdownIconColor={palettes.grey[1]}
						itemStyle={styles.dropdownItem}
					>
						{RoleDropdownOptions.map(option => (
							<Picker.Item {...option} />
						))}
					</Picker>
					{/* <Icon
						name="more-horizontal"
						style={{
							marginLeft: getResponsiveStyle(25),
						}}
						size={20}
						color={palettes.grey[10]}
					/> */}
				</ContentContainer>
			)}
			{type === 'singleOrder' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					style={styleContentContainer(hovered)}
				>
					{userData &&
						userData.length &&
						userData.map((data, index) => (
							<Text
								key={index}
								numberOfLines={1}
								style={[
									styles.textStyle,
									styleUserData(headers, index, hovered),
								]}
							>
								{data}
							</Text>
						))}
				</ContentContainer>
			)}
			{type === 'order' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					style={styleContentContainer(hovered)}
				>
					{hasCheckbox && (
						<View
							style={{
								marginRight: getResponsiveStyle(24),
								width: 25,
								height: 25,
							}}
						>
							{(hovered || isChecked) && (
								<Checkbox
									isChecked={isChecked}
									onChange={() => {
										onChangeCheckbox &&
											onChangeCheckbox(id);
										setIsChecked(!isChecked);
									}}
								/>
							)}
						</View>
					)}

					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 0, hovered),
						]}
					>
						{'#' + user.orderId}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 1, hovered),
						]}
					>
						{date
							? date.getDate() +
							'/' +
							(date.getMonth() + 1) +
							'/' +
							date.getFullYear()
							: '...'}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 2, hovered),
						]}
					>
						{user?.brands?.length > 1
							? 'Multiple'
							: user?.brands?.[0]?.name}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 3, hovered),
						]}
					>
						{`${getSymbolFromCurrency('eur')}` +
							user?.purchasedTotalAmount}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 4, hovered),
						]}
					>
						{user?.associatedUser?.username}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 5, hovered),
						]}
					>
						{'x' + qty}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 6, hovered),
						]}
					>
						{manipulateString.capitalize(
							user?.paymentStatus?.toLowerCase()
						)}
					</Text>

					<View style={styles.actionContainer}>
						{viewButton && (
							<Text
								onPress={() =>
									navigation.navigate('OrderDetails', {
										_id: user._id,
										orderDetails: user,
									})
								}
								style={{
									fontSize: getResponsiveStyle(14, 'font'),
									lineHeight: getResponsiveStyle(14, 'font'),
									color: palettes.primary[0],
								}}
							>
								View
							</Text>
						)}

						{adminButton && (
							<Picker
								selectedValue={dropdownValue}
								onValueChange={itemValue => { }}
								style={[styles.dropdownContainer]}
								dropdownIconColor={palettes.grey[1]}
								itemStyle={styles.dropdownItem}
							>
								{RoleDropdownOptions.map(option => (
									<Picker.Item {...option} />
								))}
							</Picker>
						)}

						{/* <Icon
							name="more-horizontal"
							style={{
								marginLeft: getResponsiveStyle(25),
							}}
							size={20}
							color={palettes.grey[10]}
						/> */}
					</View>
				</ContentContainer>
			)}
			{type === 'brand' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
				// style={styleContentContainer(hovered)}
				>
					<Pressable
						onPress={() => {
							navigation.navigate('BrandProfile', {
								_id: user?._id,
							});
						}}
						style={styleContentContainer(hovered)}
					>
						{userData &&
							userData.length &&
							userData.map((data, index) => (
								<Text
									key={index}
									numberOfLines={1}
									style={[
										styles.textStyle,
										styleUserData(headers, index, hovered),
									]}
								>
									{index === 0 && (
										<ProfileIcon
											style={styleProfileIcon()}
											source={{ uri: userImage }}
											userId={user?._id}
										/>
									)}
									{data}
								</Text>
							))}

						<View style={{ marginLeft: getResponsiveStyle(20) }}>
							<Button
								title={'Edit'}
								variant="primary"
								onPress={() => {
									navigation.navigate('BrandDetails', {
										brandId: user._id,
									});
								}}
							/>
						</View>
					</Pressable>
				</ContentContainer>
			)}
			{type === 'tag' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					style={styleContentContainer(hovered)}
				>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 0, hovered),
						]}
					>
						{user?.value?.value1}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 1, hovered),
						]}
					>
						{user?.value?.value2}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 2, hovered),
						]}
					>
						{user?.value?.value3}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 3, hovered),
						]}
					>
						{user?.createdBy}
					</Text>
					<Text
						numberOfLines={1}
						style={[
							styles.textStyle,
							styleUserData(headers, 4, hovered),
						]}
					>
						{user?.lastModifiedBy ?? 'N/A'}
					</Text>

					<View style={styles.actionSecondContainer}>
						<Button
							title={'Edit'}
							variant="grey"
							onPress={() => {
								navigation.navigate('tagForm', {
									tag: {
										key: user?.selected,
										description: user?.description,
										value: {
											value1: user?.value?.value1,
											value2: user?.value?.value2,
											value3: user?.value?.value3,
										},
									},
								});
							}}
						/>
						<Button
							title={'Delete'}
							variant="primary"
							onPress={() => {
								setModalChild(
									<TagDeletion
										keyValue={user?.key}
										setModalState={setIsModal}
									/>
								);
								setModalDetails({
									header: '',
									children: <></>,
									onClose: () => setIsModal(false),
									openState: isModal,
									type: 'modal',
									slideInRight: true,
									isScrollable: true,
								});

								setIsModal(!isModal);
							}}
						/>
					</View>
				</ContentContainer>
			)}
			{type === 'voucher' && (
				<ContentContainer
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					style={styleContentContainer(hovered)}
				>
					{userData && userData.length && userData.map((data, index) => (
						<Text
							key={index}
							numberOfLines={1}
							style={[styles.textStyle, styleUserData(headers, index, hovered)]}
						>

							{data}
						</Text>
					))}
				</ContentContainer>
			)}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	contentContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		minWidth: 'max-content',
		flex: 1,
		borderWidth: 1,
		borderColor: 'transparent',
	},
	contentContainerHovered: {
		borderWidth: 1,
		borderColor: palettes.grey[3],
		borderRadius: 6,
		// color:'green',
		// fontWeight:"bold",
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
	},
	firstCell: {
		fontWeight: '600',
		flexDirection: 'row',
		alignItems: 'center',
		display: 'flex',
	},
	actionContainer: {
		flex: 1,
		// minWidth: 'max-content',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		marginLeft: '5px',
		minWidth: getResponsiveStyle(200),
	},
	actionSecondContainer: {
		flex: 1,
		// minWidth: 'max-content',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		minWidth: getResponsiveStyle(100),
	},
	actionButtonMarginRight: {
		marginRight: 16,
	},
	textStyle: {
		// alignItems: 'center',
		// display: 'flex',
		// flexDirection: 'row',
		paddingLeft: 5,
	},
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',

		textUnderlineOffset: 2,
		color: palettes.purple[0],
		cursor: 'pointer',
	},
	hoverTextStyle1: {
		fontWeight: 'bold',
	},

	input: {
		width: '100%',
	},
	dropdownContainer: {
		width: '100',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(30, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		paddingLeft: getResponsiveStyle(19),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
});

const styleUserData = (
	headers: TTableHeader[],
	index: number,
	hovered: boolean
) => {
	return [
		{
			width: headers[index]?.width ?? 120,
		},
		index === 0 && styles.firstCell,
		hovered && styles.hoverTextStyle1,
	];
};

const styleContentContainer = (hovered: boolean) => {
	return [
		styles.contentContainer,
		{
			paddingVertical: getResponsiveStyle(16),
			paddingLeft: getResponsiveStyle(16),
			paddingRight: getResponsiveStyle(20),
		} as TStyle,
		hovered && styles.contentContainerHovered,
	];
};

const styleProfileIcon = () => {
	return {
		width: getResponsiveStyle(32, 'dimensions'),
		height: getResponsiveStyle(32, 'dimensions'),
		marginRight: getResponsiveStyle(15),
	};
};

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline() {
	return {
		outlineColor: palettes.primary[0],
		outerWidth: 1,
	} as any;
}
