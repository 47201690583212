import { useQuery } from '@apollo/client';
import { TGetAllVouchersResponse } from '../../types/voucherTypes';
import { GET_ALL_VOUCHERS } from '../../graphql/voucher/queries';

type GetAllVouchersArgs = {
  applicableBrandId?: string;
};

type UseGetAllVouchersProps = {
  args?: GetAllVouchersArgs;
  onCompleted?: (data: TGetAllVouchersResponse) => void;
  onError?: (error: any) => void;
};

const useGetAllVouchers = ({ args, onCompleted, onError }: UseGetAllVouchersProps = {}) => {
  const { data, loading, error, refetch } = useQuery<TGetAllVouchersResponse>(
    GET_ALL_VOUCHERS,
    {
      variables: args ? { args } : undefined,
      onCompleted,
      onError,
      fetchPolicy: "network-only"
    }
  );

  return {
    data,
    loading,
    error,
    refetch,
  };
};

export default useGetAllVouchers; 