import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Text, ScrollView, Pressable } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { TUseNavigation } from '../types/types'
import DesktopTemplate from '../components/common/templates/DesktopTemplate'
import { Button, Tabs } from '../components'
import useGetAllVouchers from '../api/voucher/useGetAllVouchers'
import { useSetAlert } from '../hooks/useSetAlerts'
import Loader from '../components/common/loader/Loader'
import { palettes } from '../config'
import { getResponsiveStyle } from '../utils/getResponsiveStyle'
import { TVoucher } from '../types/voucherTypes'
import { useMutation } from '@apollo/client'
import { DELETE_VOUCHER } from '../graphql/voucher/mutations'
import { formateDateString } from '../utils/manipulateString'
import TableComponent from '../components/common/table-component/TableComponent'
import { TTableHeader } from '../types/types'
import { useAppSelector } from '../redux/store'

const TABS = ['All Vouchers'];
const Headers: TTableHeader[] = [
    { width: 200, headerLabel: 'Code' },
    { width: 200, headerLabel: 'Description' },
    { width: 150, headerLabel: 'Discount' },
    { width: 150, headerLabel: 'Max Uses' },
    { width: 150, headerLabel: 'Current Uses' },
    { width: 150, headerLabel: 'Expiry Date' },
    { width: 100, headerLabel: 'Actions' },
];

const VoucherScreen = () => {
    const navigation = useNavigation<TUseNavigation>();
    const { setAlert } = useSetAlert();
    const [selectedTab, setSelectedTab] = useState(TABS[0]);
    const [vouchers, setVouchers] = useState<any[]>([]);

    const { loggedInUserDetails } = useAppSelector(state => ({
        loggedInUserDetails: state.auth.loggedInUserDetails,
    }));

    // Check if the user has an affiliated brand
    const affiliatedBrandId = loggedInUserDetails?.affiliatedBrand || undefined;

    // Handle navigation to edit voucher
    const handleEditVoucher = (voucher: TVoucher) => {
        navigation.navigate('VoucherCreate', { voucherId: voucher.id });
    };

    // Delete voucher mutation
    const [deleteVoucher, { loading: deleteLoading }] = useMutation(DELETE_VOUCHER, {
        onCompleted: (data) => {
            if (data?.deleteUserVoucher?.success) {
                setAlert('Voucher deleted successfully', 'normal');
                refetch(); // Refresh the list
            } else {
                setAlert(data?.deleteUserVoucher?.message || 'Failed to delete voucher', 'danger');
            }
        },
        onError: (error) => {
            setAlert(error.message || 'An error occurred while deleting the voucher', 'danger');
        }
    });

    const handleDeleteVoucher = (id: string) => {
        if (window.confirm('Are you sure you want to delete this voucher?')) {
            deleteVoucher({ variables: { id } });
        }
    };

    // Format the voucher data for the table
    const formatVoucherData = (vouchersData: TVoucher[]) => {
        return vouchersData.map(voucher => [
            voucher.code,
            voucher.description,
            `${voucher.discountPercentage}%`,
            voucher.maxUses.toString(),
            voucher.currentUses.toString(),
            formateDateString(voucher.expiryDate),
            <Button
                key={voucher.id}
                title="Delete"
                size="sm"
                variant="primary"
                onPress={() => handleDeleteVoucher(voucher.id)}
                loading={deleteLoading}
            />,
            <Button
                key={voucher.id}
                title="Edit"
                size="sm"
                variant="grey-outline"
                onPress={() => handleEditVoucher(voucher)}
                loading={deleteLoading}
            />
        ]);
    };

    // Fetch vouchers, passing the affiliated brand ID if available
    const { data, loading, error, refetch } = useGetAllVouchers({
        args: affiliatedBrandId ? { applicableBrandId: affiliatedBrandId } : undefined,
        onCompleted: (data) => {
            if (data?.getAllUserVouchers?.success && data.getAllUserVouchers.data) {
                setVouchers(formatVoucherData(data.getAllUserVouchers.data.data));
            }
        },
        onError: (error) => {
            setAlert(error.message || 'Failed to fetch vouchers', 'danger');
        }
    });


    return (
        <DesktopTemplate
            navigationBarProps={{
                hasAddNewProduct: false,
            }}
        >
            <Tabs
                tabs={TABS}
                selectedTab={selectedTab}
                onPress={tab => {
                    setSelectedTab(tab);
                }}
                showDropdowns
                actionButtonProps={{
                    label: 'Create Voucher',
                    onPress: () => navigation.navigate('VoucherCreate'),
                }}
            />

            {loading ? (
                <Loader size={getResponsiveStyle(16, 'dimensions')} />
            ) : (
                vouchers.length > 0 ? (
                    <TableComponent
                        type="voucher"
                        data={vouchers}
                        dataDetail={vouchers}
                        showHeader={false}
                        headers={Headers}
                        tableContentProps={{
                            hasCheckbox: false,
                            viewButton: false,
                        }}
                    />
                ) : (
                    <View style={styles.emptyState}>
                        <Text style={styles.emptyStateText}>No vouchers found</Text>
                    </View>
                )
            )}
        </DesktopTemplate>
    )
}

const styles = StyleSheet.create({
    emptyState: {
        padding: 24,
        alignItems: 'center',
        marginTop: 20,
        borderWidth: 1,
        borderColor: palettes.grey[4],
        borderRadius: 8,
    },
    emptyStateText: {
        color: palettes.grey[1],
        fontSize: getResponsiveStyle(16, 'font'),
        padding: 24,
    },
});

export default VoucherScreen 