import { useMutation } from '@apollo/client';
import { TUpdateVoucherResponse } from '../../types/voucherTypes';
import { UPDATE_VOUCHER } from '../../graphql/voucher/mutations';

type UpdateVoucherInput = {
  _id?: string;
  code?: string;
  description?: string;
  discountPercentage?: number;
  maxUses?: number;
  expiryDate?: string;
  applicableBrandId?: string;
  productId?: string;
};

type UseUpdateVoucherProps = {
  onCompleted?: (data: TUpdateVoucherResponse) => void;
  onError?: (error: any) => void;
};

const useUpdateVoucher = ({ onCompleted, onError }: UseUpdateVoucherProps = {}) => {
  const [updateVoucherMutation, { data, loading, error }] = useMutation<
    TUpdateVoucherResponse,
    { args: UpdateVoucherInput }
  >(UPDATE_VOUCHER, {
    onCompleted,
    onError,
  });

  const executeUpdateVoucherMutation = (id: string, input: UpdateVoucherInput) => {
    return updateVoucherMutation({
      variables: {
        args: { _id: id, ...input },
      },
    });
  };

  return {
    updateVoucher: executeUpdateVoucherMutation,
    data,
    loading,
    error,
  };
};

export default useUpdateVoucher; 