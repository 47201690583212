import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useGetUserMetricChange } from '../api/auth/useGetUserMetricChange';
import { useGetAllBrands } from '../api/brands/useGetAllBrands';
import { useGetAllNewBrands } from '../api/brands/useGetAllNewBrands';
import Loader from '../components/common/loader/Loader';
import PageHeader from '../components/common/page-header/PageHeader';
import PeopleScrollSection from '../components/common/people-scroll-section/PeopleScrollSection';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ViewAllText from '../components/common/view-all-text/ViewAllText';
import { TProfileData } from '../components/dashboard/profile-list/ProfileList';
import {
	GET_ALL_BRANDS,
	GET_BRANDS_REVENUE,
	GET_BRAND_GRAPH_DATA,
	GET_BRAND_STATS,
	GET_NEW_BRANDS,
} from '../graphql/brands/queries';

import { useSetAlert } from '../hooks/useSetAlerts';
import {
	TGetAllBrandsData,
	TGetAllBrandsResponse,
	TBrandsTableData,
} from '../types/brandTypes';
import { TFind, TODO, TPaginatedOptions } from '../types/types';
import { TStatsforTable } from '../types/userTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useAppSelector } from '../redux/store';
const TABS = ['All Brands', 'New Brands'];

const HEADERS = [
	{
		headerLabel: 'Company',
		width: getResponsiveStyle(200),
	},
	{
		headerLabel: 'Website',
		width: getResponsiveStyle(150),
	},

	{
		headerLabel: 'Location',
		width: getResponsiveStyle(200),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Products',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Events',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: `Earnings(${getSymbolFromCurrency('eur')})`,
		width: getResponsiveStyle(100),
	},
	// {
	// 	headerLabel: `Available Earnings(${getSymbolFromCurrency('eur')})`,
	// 	width: getResponsiveStyle(100),
	// },

];

function BrandsScreen() {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const { setAlert } = useSetAlert();
	const [allBrandsData, setAllBrandsData] = useState<TBrandsTableData[]>();
	const [brandStats, setBrandStats] = useState<TStatsforTable>();
	const [newBrands, setnewBrands] = useState<TBrandsTableData[]>();
	const [SelectedBrandData, setselected] = useState<TBrandsTableData[]>();
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();
	const [stats, setStats] = useState<any[]>([]);
	const [statIds, setStatIds] = useState<string[]>([]);
	const [brandIds, setbrandIds] = useState<any[]>([]);
	const [earned, setEarned] = useState<any[]>([]);
	const tableBrandDataSelector = (data?: TBrandsTableData[]) => {
		if (!checkIfBrandsDataExists(data)) {
			console.log('error');
			return;
		}
		console.log(data);
		return data as TBrandsTableData[];
	};

	const checkIfBrandsDataExists = (data?: TBrandsTableData[]) => {
		return data ? true : false;
	};
	const [getStats, { loading: loading }] = useLazyQuery(GET_BRAND_STATS, {
		onCompleted: data => {
			if (
				data.getBrandStats &&
				data.getBrandStats.success &&
				data.getBrandStats.data
			) {
				setStats([...stats, ...data.getBrandStats.data.data]);
			} else {
				setAlert('Unable to fetch brand stats', 'danger');
			}
		},
		onError: () => {
			console.log('ther was an error');
		},
	});
	useEffect(() => {
		getStats({
			variables: {
				_ids: statIds,
			},
		});
	}, [statIds]);
	const [getBrandsRevenue] = useLazyQuery(GET_BRANDS_REVENUE, {
		onCompleted: data => {

			setEarned([
				...earned,
				...data?.getBrandTrxDetails?.data?.data,
			]);
		},
	});

	useEffect(() => {
		getBrandsRevenue({
			variables: {
				ids: brandIds,
			},
		});

	}, [brandIds]);

	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	const [
		getAllBrands,
		{ data: dataGetAllBrands, loading: loadingGetAllBrands },
	] = useLazyQuery<TGetAllBrandsResponse, TPaginatedOptions>(
		GET_ALL_BRANDS,
		{
			variables: {
				limit: 10,
				...(loggedInUserDetails && loggedInUserDetails.affiliatedBrand ? { find: { _id: loggedInUserDetails.affiliatedBrand } } : {})
			},

			onCompleted: data => {
				if (
					data.getAllBrands.data &&
					data.getAllBrands.success &&
					data.getAllBrands.data.data
				) {
					let ids = data.getAllBrands.data.data.map(
						brand => brand?._id
					);
					setbrandIds([...brandIds, ...ids]);

					setStatIds([
						...statIds,
						...data.getAllBrands.data.data.map(o => o._id),
					]);
					let arrTotal: any = data?.getAllBrands?.data?.data?.map(
						brand => ({
							connectId:
								brand.connectAccountInfo?.connectAccountId,
							profileImage: brand.profileImage,
							companyName: brand.name,
							website: brand.website ?? 'N/A',
							location: brand?.address?.countryLabel,
							_id: brand._id,
						})
					);
					setAllBrandsData(arrTotal);
					setBrandStats({
						totalnum: data.getAllBrands.data.total,
						active: data.getAllBrands.data.totalActive,
						inactive: data.getAllBrands.data.totalInactive,
						newRequest: data.getAllBrands.data.newRequest,
					});
				} else {
					setAlert('Unable to get all brands', 'danger');
					setAllBrandsData([]);
				}
			},
			onError: () => {
				setAlert('Unable to get all brands', 'danger');
				setAllBrandsData([]);
			},
		}
	);

	const [
		getNewBrands,
		{ data: dataGetAllNewBrands, loading: loadingGetAllNewBrands },
	] = useLazyQuery(GET_NEW_BRANDS, {
		variables: {
			limit: 10,
		},
		onCompleted: data => {
			console.log(data);
			if (
				!(
					data.getNewBrands.data &&
					data.getNewBrands.success &&
					data.getNewBrands.data.data
				)
			) {
				setAlert('Unable to get new brands', 'danger');
				setAllBrandsData([]);
			} else {
				let ids = data.getNewBrands.data.data.map(
					brand => brand?._id
				);
				setbrandIds([...brandIds, ...ids]);
				setStatIds([
					...statIds,
					...data.getNewBrands.data.data.map(o => o._id),
				]);
				let arrTotal = data?.getNewBrands?.data?.data?.map(brand => ({
					connectId: brand.connectAccountInfo?.connectAccountId,
					profileImage: brand.profileImage,
					companyName: brand.name,
					website: brand.website ?? 'N/A',
					location: brand?.address?.countryLabel,
					_id: brand._id,
				}));

				setnewBrands(arrTotal);
			}
		},
		onError: () => {
			setAlert('Unable to get new brands', 'danger');
			setAllBrandsData([]);
		},
	});
	useEffect(() => {
		getAllBrands();
		getNewBrands();
	}, []);
	const onPressTab = (tab: string) => {
		setSelectedTab(tab);

		if (tab === 'New Brands') {
			setselected(tableBrandDataSelector(newBrands));
		}
		if (tab === 'All Brands') {
			setselected(tableBrandDataSelector(allBrandsData));
		}
	};

	useEffect(() => {
		setselected(tableBrandDataSelector(allBrandsData));
	}, [allBrandsData]);

	const unifiedLoading = useMemo(
		() =>
			getSingleLoadingState(loadingGetAllBrands, loadingGetAllNewBrands),
		[loadingGetAllBrands, loadingGetAllNewBrands]
	);
	useQuery(GET_BRAND_GRAPH_DATA, {
		onCompleted: data => {
			if (
				data.getBrandsGraphData.success &&
				data.getBrandsGraphData.data
			) {
				let currMonth = data.getBrandsGraphData.data.currentMonth.map(
					(value: { dataPoint: number }) => {
						return value.dataPoint;
					}
				);
				let prevMonth = data.getBrandsGraphData.data.previousMonth.map(
					(value: { dataPoint: number }) => {
						return value.dataPoint;
					}
				);
				setPrevGraphData(prevMonth);
				setCurrGraphData(currMonth);
			}
		},
	});
	const brandTab = loggedInUserDetails?.role === "BRAND_ADMIN" ? TABS?.slice(0, 1) : TABS

	return (
		<DesktopTemplate
			navigationBarProps={{
				hasAddNewBrand: true,
			}}
		>
			<PageHeader
				prevData={PrevGraphData}
				currData={CurrGraphData}
				pageHeader="All Brands"
				chartItemValues={[
					{
						title: 'All Brands',
						percentageDifference: 10,
						percentageIncrease: true,
						value: `${brandStats ? brandStats.totalnum : 0}`,
					},
					{
						title: 'Active Brands',
						percentageDifference: 10,
						percentageIncrease: true,
						value: `${brandStats ? brandStats.active : 0}`,
					},
					{
						title: 'Inactive Brands',
						percentageDifference: 10,
						percentageIncrease: false,
						value: `${brandStats ? brandStats.inactive : 0}`,
					},
					{
						title: 'New Requets',
						percentageDifference: 10,
						percentageIncrease: true,
						value: `${brandStats ? brandStats.newRequest : 0}`,
					},
				]}
			/>
			{/* <PeopleScrollSection
				header="New Brands Requests"
				cardProps={{
					isBrand: true,
					isRequest: true,
					
				}}
				
				navigationDestination="BrandsRequests"
			/> */}
			<View
				style={{
					marginTop: getResponsiveStyle(38),
				}}
			>
				{unifiedLoading ? (
					<Loader />
				) : (
					!!selectedTab && (
						<TableComponent
							type="brand"
							headerTabs={brandTab}
							onPressTab={onPressTab}
							selectedTab={selectedTab}
							data={SelectedBrandData?.map(val => ({
								profileImage: val.profileImage,
								companyName: val.companyName,
								website: val.website,
								location: val.location,
								followers:
									stats?.find(
										(value: { brandId: string }) =>
											value.brandId === val._id
									)?.followersCount ?? '...',
								products:
									stats?.find(
										(value: { brandId: string }) =>
											value.brandId === val._id
									)?.productsCount ?? '...',
								events:
									stats?.find(
										(value: { brandId: string }) =>
											value.brandId === val._id
									)?.eventsCount ?? '...',
								earnings:
									earned.find(
										earning =>
											earning.brandId === val?._id
									)?.grossEarnings?.toFixed(2) ?? 'N/A',
								_id: val._id,
								// availableEarnings:
								// 	earned.find(
								// 		earning =>
								// 			earning.brandId === val?._id
								// 	)?.availableEarning?.toFixed(2) ?? 'N/A',


							}))}
							headers={HEADERS}
							loading={unifiedLoading}
						/>
					)
				)}
			</View>
			<View
				style={{
					marginTop: getResponsiveStyle(33),
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<ViewAllText
					title="View All Brands"
					navigationDestination="AllBrands"
				/>
			</View>
		</DesktopTemplate>
	);
}

export default BrandsScreen;
