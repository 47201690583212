import { gql } from '@apollo/client';

export const CREATE_VOUCHER = gql`
  mutation createUserVoucher($args: CreateUserVoucherInput!) {
    createUserVoucher(args: $args) {
      success
      statusCode
      message
    }
  }
`;

export const UPDATE_VOUCHER = gql`
  mutation updateUserVoucher($args: UpdateUserVoucherInput!) {
    updateUserVoucher(args: $args) {
      success
      statusCode
      message
    }
  }
`;

export const DELETE_VOUCHER = gql`
  mutation deleteUserVoucher($id: ID!) {
    deleteUserVoucher(id: $id) {
      success
      statusCode
      message
    }
  }
`; 