type TManipulateString = {
	titleCase: (str?: string) => string;
	capitalize: (str?: string) => string;
	extractFloatsFromString: (str?: string) => number;
};
export const manipulateString: TManipulateString = {
	titleCase(str = '') {
		var splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			splitStr[i] =
				splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		return splitStr.join(' ');
	},
	capitalize(str = '') {
		return str.slice(0, 1).toLocaleUpperCase() + str.slice(1);
	},
	extractFloatsFromString(str = '') {
		if (str === '') return 0;
		const EXTRACT_FLOATS_FROM_STRING_AS_STRING = /\d+(\.\d+)?/;
		const floatsAsStrings =
			str && str.match(EXTRACT_FLOATS_FROM_STRING_AS_STRING);
		return floatsAsStrings ? +floatsAsStrings[0] : 0;
	},
};

export const formateDateString = (dateString: string) => {
	return new Date(+dateString).toLocaleString("en-US").split(',')[0];
};
