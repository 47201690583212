import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import * as yup from 'yup'
import { yupsRequiredString } from '../utils/yupsRequiredString'
import { Button, HeadingLarge, Input, Spacing, DateTimePicker } from '../components'
import { getResponsiveStyle } from '../utils/getResponsiveStyle'
import { useSetAlert } from '../hooks/useSetAlerts'
import { useNavigation, useRoute } from '@react-navigation/native'
import { TUseNavigation, TDropdownObject, TUseRoute } from '../types/types'
import DesktopTemplate from '../components/common/templates/DesktopTemplate'
import { useGetAllBrands } from '../api/brands/useGetAllBrands'
import useGetAllProducts from '../api/product/useGetAllProducts'
import Dropdown2 from '../components/common/dropdown2/dropdown2'
import useCreateVoucher from '../api/voucher/useCreateVoucher'
import useUpdateVoucher from '../api/voucher/useUpdateVoucher'
import useGetVoucherById from '../api/voucher/useGetVoucherById'
import { TCreateVoucher, TVoucher } from '../types/voucherTypes'
import { GoBackButton } from '../components'
import Loader from '../components/common/loader/Loader'
import { useAppSelector } from '../redux/store'

type TVoucherFormValues = {
    code: string;
    description: string;
    discountPercentage: string;
    maxUses: string;
    expiryDate: string;
    applicableBrandId: string;
    productId: string;
}

const VoucherCreateScreen = () => {
    const navigation = useNavigation<TUseNavigation>();
    const route = useRoute<TUseRoute<'VoucherCreate'>>();
    const { voucherId } = route.params || {};
    const isEditMode = !!voucherId;

    const { setAlert } = useSetAlert();
    const [brandOptions, setBrandOptions] = useState<TDropdownObject[]>([]);
    const [productOptions, setProductOptions] = useState<TDropdownObject[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<TDropdownObject | undefined>(undefined);
    const [selectedProduct, setSelectedProduct] = useState<TDropdownObject | undefined>(undefined);
    const [voucher, setVoucher] = useState<TVoucher | null>(null);
    const [initialValues, setInitialValues] = useState<TVoucherFormValues>({
        code: '',
        description: '',
        discountPercentage: '',
        maxUses: '',
        expiryDate: '',
        applicableBrandId: '',
        productId: ''
    });

    const { loggedInUserDetails } = useAppSelector(state => ({
        loggedInUserDetails: state.auth.loggedInUserDetails,
    }));

    // Fetch voucher data for editing
    const { getVoucherById, loading: voucherLoading } = useGetVoucherById({
        onCompleted: (data) => {
            if (data?.getUserVoucher?.success && data.getUserVoucher.data) {
                const voucherData = data.getUserVoucher.data;
                setVoucher(voucherData);

                // Format date to YYYY-MM-DD for input
                const expiryDate = new Date(+voucherData.expiryDate);
                const formattedDate = expiryDate.toISOString().split('T')[0];

                // Update initial values
                setInitialValues({
                    code: voucherData.code,
                    description: voucherData.description,
                    discountPercentage: voucherData.discountPercentage.toString(),
                    maxUses: voucherData.maxUses.toString(),
                    expiryDate: formattedDate,
                    applicableBrandId: voucherData.brandId || '',
                    productId: voucherData.productId || ''
                });

                // Set selected brand and product if they exist
                if (voucherData.brandId && brandOptions.length > 0) {
                    const brand = brandOptions.find(b => b.value === voucherData.brandId);
                    if (brand) setSelectedBrand(brand);
                }

                if (voucherData.productId && productOptions.length > 0) {
                    const product = productOptions.find(p => p.value === voucherData.productId);
                    if (product) setSelectedProduct(product);
                }
            } else {
                setAlert('Failed to fetch voucher details', 'danger');
            }
        },
        onError: (error) => {
            setAlert(error.message || 'An error occurred while fetching voucher details', 'danger');
        }
    });

    // Fetch brands for dropdown
    const { getAllBrands, data: brandData, loading: brandsLoading } = useGetAllBrands();

    // Fetch products for dropdown
    const { executeAllProductQuery, data: productData, loading: productsLoading } = useGetAllProducts({
        onCompletedGetAllProducts: () => { }
    });

    // Create voucher hook
    const onCompletedCreateVoucher = (data: any) => {
        if (data?.createUserVoucher?.success) {
            setAlert('Voucher created successfully!', 'normal');
            navigation.navigate('Voucher');
        } else {
            setAlert(data?.createUserVoucher?.message || 'Failed to create voucher', 'danger');
        }
    };

    const { createVoucher, loading: createVoucherLoading } = useCreateVoucher({
        onCompleted: onCompletedCreateVoucher,
        onError: (error) => {
            setAlert(error.message || 'An error occurred while creating the voucher', 'danger');
        }
    });

    // Update voucher hook
    const onCompletedUpdateVoucher = (data: any) => {
        if (data?.updateUserVoucher?.success) {
            setAlert('Voucher updated successfully!', 'normal');
            navigation.navigate('Voucher');
        } else {
            setAlert(data?.updateUserVoucher?.message || 'Failed to update voucher', 'danger');
        }
    };

    const { updateVoucher, loading: updateVoucherLoading } = useUpdateVoucher({
        onCompleted: onCompletedUpdateVoucher,
        onError: (error) => {
            setAlert(error.message || 'An error occurred while updating the voucher', 'danger');
        }
    });

    useEffect(() => {
        getAllBrands();

        // If in edit mode, fetch the voucher data
        if (isEditMode && voucherId) {
            getVoucherById(voucherId);
        }
    }, [isEditMode, voucherId]);

    useEffect(() => {
        if (brandData?.length) {
            const brands = brandData.map((brand: any) => ({
                label: brand.companyName,
                value: brand._id
            }));

            if (loggedInUserDetails?.affiliatedBrand) {
                setBrandOptions(brands.filter((brand: any) => brand.value === loggedInUserDetails?.affiliatedBrand));
            } else {
                setBrandOptions(brands);
            }

            // If in edit mode and we have a brandId, set the selected brand
            if (isEditMode && voucher?.applicableBrandId?._id) {
                const brand = brands.find((b: { value: string; }) => b.value === voucher.applicableBrandId._id);
                if (brand) setSelectedBrand(brand);
            }
        }
    }, [brandData, isEditMode, voucher]);


    useEffect(() => {
        if (productData?.getAllProducts?.data?.data) {
            const products = productData.getAllProducts.data.data.map((product: any) => ({
                label: product.name,
                value: product._id
            }));
            setProductOptions(products);

            // If in edit mode and we have a productId, set the selected product
            if (isEditMode && voucher?.productId) {
                const product = products.find((p: { value: string; }) => p.value === voucher.productId);
                if (product) setSelectedProduct(product);
            }
        }
    }, [productData, isEditMode, voucher]);

    const validationSchema = yup.object({
        code: yupsRequiredString(),
        description: yupsRequiredString(),
        discountPercentage: yup.number()
            .required('Discount percentage is required')
            .min(1, 'Discount must be at least 1%')
            .max(100, 'Discount cannot exceed 100%'),
        maxUses: yup.number()
            .required('Maximum uses is required')
            .min(1, 'Must allow at least 1 use'),
        expiryDate: yupsRequiredString(),
    });

    const onSubmitVoucher = (values: TVoucherFormValues) => {
        const voucherData: {
            code: string;
            description: string;
            discountPercentage: number;
            maxUses: number;
            expiryDate: string;
            applicableBrandId?: string;
            productId?: string;
        } = {
            code: values.code,
            description: values.description,
            discountPercentage: Number(values.discountPercentage),
            maxUses: Number(values.maxUses),
            expiryDate: values.expiryDate,
        };

        if (values.applicableBrandId) {
            voucherData.applicableBrandId = values.applicableBrandId;
        }

        if (values.productId) {
            voucherData.productId = values.productId;
        }

        if (isEditMode && voucherId) {
            // Update existing voucher
            updateVoucher(voucherId, voucherData);
        } else {
            // Create new voucher
            createVoucher(voucherData as TCreateVoucher);
        }
    };

    const isLoading = voucherLoading || brandsLoading || productsLoading;
    const isSubmitting = createVoucherLoading || updateVoucherLoading;

    if (isEditMode && isLoading) {
        return (
            <DesktopTemplate>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Loader size={getResponsiveStyle(16, 'dimensions')} />
                </View>
            </DesktopTemplate>
        );
    }

    return (
        <DesktopTemplate>
            <GoBackButton history="Voucher" />
            <View style={{ alignItems: 'center' }}>
                <View style={{ width: getResponsiveStyle(465, 'dimensions') }}>
                    <HeadingLarge
                        heading={isEditMode ? "Edit Voucher" : "Create Voucher"}
                        text={isEditMode
                            ? "Update the voucher details below"
                            : "Fill out all the mandatory fields to create a voucher"}
                    />
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={onSubmitVoucher}
                        validationSchema={validationSchema}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            touched,
                            errors,
                            values,
                            dirty,
                            isValid
                        }) => (
                            <>
                                <View style={{ marginTop: 16, display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
                                    <Input
                                        label="Voucher Code*"
                                        onChangeText={handleChange('code')}
                                        onBlur={handleBlur('code')}
                                        value={values.code}
                                        error={
                                            touched.code && errors.code
                                                ? errors.code
                                                : ''
                                        }
                                        containerStyles={{ marginBottom: 16 }}
                                    />

                                    <Input
                                        label="Description*"
                                        onChangeText={handleChange('description')}
                                        onBlur={handleBlur('description')}
                                        value={values.description}
                                        error={
                                            touched.description && errors.description
                                                ? errors.description
                                                : ''
                                        }
                                        containerStyles={{ marginBottom: 16 }}
                                    />

                                    <Input
                                        label="Discount Percentage (%)*"
                                        onChangeText={(text) => {
                                            // Only allow numbers
                                            const numericValue = text.replace(/[^0-9]/g, '');
                                            setFieldValue('discountPercentage', numericValue);
                                        }}
                                        onBlur={handleBlur('discountPercentage')}
                                        value={values.discountPercentage}
                                        keyboardType="numeric"
                                        error={
                                            touched.discountPercentage && errors.discountPercentage
                                                ? errors.discountPercentage as string
                                                : ''
                                        }
                                        containerStyles={{ marginBottom: 16 }}
                                    />

                                    <Input
                                        label="Maximum Uses*"
                                        onChangeText={(text) => {
                                            // Only allow numbers
                                            const numericValue = text.replace(/[^0-9]/g, '');
                                            setFieldValue('maxUses', numericValue);
                                        }}
                                        onBlur={handleBlur('maxUses')}
                                        value={values.maxUses}
                                        keyboardType="numeric"
                                        error={
                                            touched.maxUses && errors.maxUses
                                                ? errors.maxUses as string
                                                : ''
                                        }
                                        containerStyles={{ marginBottom: 16 }}
                                    />

                                    <DateTimePicker
                                        label="Expiry Date*"
                                        dateTimeProps={{
                                            dateTimeProps: {
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue('expiryDate', e.target.value);
                                                },
                                                value: values.expiryDate,
                                                onBlur: () => handleBlur('expiryDate'),
                                            },
                                            type: 'date'
                                        }}
                                        containerStyles={{ marginBottom: 16 }}
                                    />
                                    {touched.expiryDate && errors.expiryDate && (
                                        <View style={{ marginTop: 8, marginBottom: 16 }}>
                                            <Text style={{ color: 'red' }}>
                                                {errors.expiryDate as string}
                                            </Text>
                                        </View>
                                    )}

                                    <Dropdown2
                                        labelProps={{ label: "Applicable Brand" }}
                                        values={selectedBrand}
                                        options={brandOptions}
                                        onChangeValue={(brand: TDropdownObject) => {
                                            setSelectedBrand(brand);
                                            setFieldValue('applicableBrandId', brand.value);
                                        }}
                                        containerStyles={{ marginBottom: 16 }}
                                    />

                                    {/* <Dropdown2
                                        labelProps={{ label: "Applicable Product" }}
                                        values={selectedProduct}
                                        options={productOptions}
                                        onChangeValue={(product: TDropdownObject) => {
                                            setSelectedProduct(product);
                                            setFieldValue('productId', product.value);
                                        }}
                                        containerStyles={{ marginBottom: 16 }}
                                    /> */}

                                    <Button
                                        title={isEditMode ? "Update Voucher" : "Create Voucher"}
                                        variant="primary"
                                        size='lg'
                                        disabled={!dirty || !isValid || isSubmitting}
                                        loading={isSubmitting}
                                        onPress={() => handleSubmit()}
                                    />
                                </View>
                            </>
                        )}
                    </Formik>
                </View>
            </View>
        </DesktopTemplate>
    )
}

export default VoucherCreateScreen 