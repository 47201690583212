import { useLazyQuery } from '@apollo/client';
import { GET_VOUCHER_BY_ID } from '../../graphql/voucher/queries';
import { TAPIResponse } from '../../types/types';
import { TVoucher } from '../../types/voucherTypes';

type TGetVoucherByIdResponse = TAPIResponse<TVoucher, 'getUserVoucher'>;

type UseGetVoucherByIdProps = {
  onCompleted?: (data: TGetVoucherByIdResponse) => void;
  onError?: (error: any) => void;
};

const useGetVoucherById = ({ onCompleted, onError }: UseGetVoucherByIdProps = {}) => {
  const [getVoucherById, { data, loading, error }] = useLazyQuery<
    TGetVoucherByIdResponse,
    { args: { _id: string } }
  >(GET_VOUCHER_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted,
    onError,
  });

  const executeGetVoucherById = (id: string) => {
    return getVoucherById({
      variables: {
        args: { _id: id },
      },
    });
  };

  return {
    getVoucherById: executeGetVoucherById,
    data,
    loading,
    error,
  };
};

export default useGetVoucherById; 